<template>
	<S2SCard title="Primary Contact">
		<v-layout row wrap v-if="selectedOrganisation">
			<v-flex xs12>
				<v-text-field hide-details readonly label="Primary Contact Name" :value="fullName"></v-text-field>
			</v-flex>
			<v-flex xs12>
				<v-text-field hide-details readonly label="Email" :value="email"></v-text-field>
			</v-flex>
			<v-flex xs12>
				<v-text-field hide-details readonly label="Phone Number" :value="number"></v-text-field>
			</v-flex>
		</v-layout>
	</S2SCard>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "OrganisationDetails",

	computed: {
		selectedOrganisation: function() {
			return this.$store.state.organisations.selectedOrganisation;
		}
	},

	data: function() {
		return {
			fullName: "",
			email: "",
			number: ""
		};
	},

	created: function() {
		this.fullName =
			this.selectedOrganisation.profile.contactPerson.profile.firstName + " " + this.selectedOrganisation.profile.contactPerson.profile.surname;
		this.email = this.selectedOrganisation.profile.contactPerson.profile.email;
		this.number = this.selectedOrganisation.profile.contactPerson.profile.cellphoneNumber;
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-text-field >>> .v-input__control > .v-input__slot:after,
.v-text-field >>> .v-input__control > .v-input__slot:before {
	border-style: none !important;
}
</style>
